<template>
  <v-container v-if="element">
    <v-row>
      <v-col>
        <v-text-field
          outlined
          label="X"
          v-model="element.attributes.x"
          @change="updateCoordinates()"
        ></v-text-field>
      </v-col>

      <v-col
        ><v-text-field
          outlined
          label="Y"
          v-model="element.attributes.y"
          @change="updateCoordinates()"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          outlined
          label="Width"
          readonly
          :value="getWidth()"
        ></v-text-field>
      </v-col>

      <v-col
        ><v-text-field
          outlined
          label="Height"
          readonly
          :value="getHeight()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-img v-if="element.attributes['xlink:href']" :src="element.attributes['xlink:href']" width="200px" />

        <v-text-field
          outlined
          v-if="element.attributes['s3url']"
          label="Image URL"
          v-model="element.attributes['s3url']"
          @change="updateAttribute('s3url', element.attributes['s3url'])"></v-text-field>

        <v-btn v-if="element.attributes['s3url']" @click="fixWhiteImagePixels(element.attributes['s3url'])">Fix Whitepixels</v-btn>  

        <!-- check if element is type image and add dropdown for preserveAspectRatio -->
        <v-select
          v-if="element.attributes['xlink:href']"
          v-model="preserveAspectRatio"
        :items="['none', 'xMidYMid slice', 'xMinYMin', 'xMidYMin', 'xMaxYMin', 'xMinYMid', 'xMidYMid', 'xMaxYMid', 'xMinYMax', 'xMidYMax', 'xMaxYMax']"
        label="Preserve Aspect Ratio"
        @change="updateAttribute('preserveAspectRatio', preserveAspectRatio)"></v-select>

        <div v-for="item in getStyles()" :key="item.key">

          <v-text-field
            outlined
            v-if="item.key != 'font-family'"
            :label="item.key"
            v-model="item.value"
            @change="updateStyle(item.key, item.value)"
          />


          <v-autocomplete
            outlined
            v-if="item.key == 'font-family'"
            :items="fonts"
            :label="item.key"
            v-model="item.value"
            @change="updateStyle(item.key, item.value)">
            {{ item.value }}
          </v-autocomplete>
          <span v-if="item.key == 'font-family'">Ausgewählte Schriftart: {{ item.value }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="element.elements && element.elements.length > 0">
        <v-text-field
          outlined
          v-model="element.elements[0].text"
          label="Text"
          @change="updateText()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="element.elements && element.elements.length > 0">
        <v-text-field
          outlined
          v-model="element.attributes.maxwidth"
          label="Maximale Breite"
          @change="updateText()"
        ></v-text-field>
      </v-col>
      <v-col cols="6" v-if="element.elements && element.elements.length > 0">
        <v-text-field
          outlined
          v-model="element.attributes.minwidth"
          label="Minimale Breite"
          @change="updateText()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="element.elements && element.elements.length > 0">
        <v-text-field
          outlined
          v-model="element.attributes.maxheight"
          label="Maximale Höhe"
          @change="updateText()"
        ></v-text-field>
      </v-col>
      <v-col cols="6" v-if="element.elements && element.elements.length > 0">
        <v-text-field
          outlined
          v-model="element.attributes.minheight"
          label="Minimale Höhe"
          @change="updateText()"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="element.name == 'text'">
      <v-btn @click="leftAlignText()"><v-icon>mdi-format-align-left</v-icon>Left Align</v-btn>
      <v-btn @click="centerText()"><v-icon>mdi-format-align-center</v-icon>Center Text</v-btn>
      <v-btn @click="rightAlignText()"><v-icon>mdi-format-align-right</v-icon>Right Align</v-btn>
    </v-row>

  </v-container>
</template>

<script>
import $ from "jquery";

import EventBus from "@/event-bus";

export default {
  name: "SVGProperties",

  data() {
    return {
      element: null,
      fonts: []
    };
  },
  computed: {
    boxWidth: function (id) {
      let boundingBox = $("#" + id)[0].getBBox();
      return boundingBox.width;
    },

    isImage() {
      return this.element.name === "image";
    },
    preserveAspectRatio: {
      get() {
        return this.element.attributes.preserveAspectRatio ? this.element.attributes.preserveAspectRatio : null;
      },
      set(value) {
        this.element.attributes.preserveAspectRatio = value;
        console.log(this.element);
        return value;
      }
    }
  },
  methods: {
    updateElement(element) {
      console.log(element);
      this.element = element;
    },

    updateFonts(fonts) {
      this.fonts = fonts;
    },

    updateCoordinates() {
      let id = this.element.attributes.id;
      $("#" + id).attr("x", this.element.attributes.x);
      $("#" + id).attr("y", this.element.attributes.y);
      EventBus.$emit("SVG_EDITED");
    },

    updateText() {
      let id = this.element.attributes.id;
      $("#" + id)[0].innerHTML = this.element.elements[0].text;

      let maxWidth = this.element.attributes.maxwidth;
      if (maxWidth) {
        $("#" + id).attr("maxwidth", maxWidth);
      }

      let minwidth = this.element.attributes.minwidth;
      if (minwidth) {
        $("#" + id).attr("minwidth", minwidth);
      }




      let maxHeight = this.element.attributes.maxheight;
      if (maxHeight) {
        $("#" + id).attr("maxheight", maxHeight);
      }

      let minheight = this.element.attributes.minheight;
      if (minheight) {
        $("#" + id).attr("minheight", minheight);
      }

      if (maxWidth && minwidth && !maxHeight && !minheight) {
        this.operationTextWidth(this.element, maxWidth, minwidth);
      } else if (!maxWidth && !minwidth && maxHeight && minheight) {
        this.operationTextHeight(this.element, maxHeight, minheight);
      } else if(maxWidth && maxHeight){
        this.operationTextSize(this.element, maxWidth, maxHeight);
      }

      let fontSize = this.element.attributes.fontSize;
      if (fontSize) {
        $("#" + id).attr("fontSize", fontSize);
      }



      EventBus.$emit("SVG_EDITED");
    },

    updateStyle(key, value) {
      let id = this.element.attributes.id;

      console.log("Updating style:");
      console.log("Key:"  + key.trim())
      console.log("Value:"  + value.trim())
      

      $("#" + id)[0].style[key.trim()] = value.trim();
      EventBus.$emit("SVG_EDITED");
    },

    updateAttribute(key, value) {
      let id = this.element.attributes.id;
      console.log("Updating attributes:");
      console.log(this.element.attributes)
      if (value == null && this.element.attributes[key]) {
        value = this.element.attributes[key];
      }
      console.log("Updating", key, value)
      $("#" + id).attr(key.trim(), value.trim());
      EventBus.$emit("SVG_EDITED");
    },

    getWidth() {
      let id = this.element.attributes.id;
      let boundingBox = $("#" + id)[0].getBBox();

      return boundingBox.width;
    },

    getHeight() {
      let id = this.element.attributes.id;
      let boundingBox = $("#" + id)[0].getBBox();
      return boundingBox.height;
    },

    getStyles() {
      let styles = this.element.attributes.style;
      let parsedStyles = [];

      if (styles) {
        let styleArray = styles.trim().split(";");
        for (let i = 0; i < styleArray.length; i++) {
          let style = styleArray[i].split(":");

          if (style[0]) {
            parsedStyles.push({
              key: style[0],
              value: style[1],
            });
          }
        }
      }

      return parsedStyles;
    },

    leftAlignText() {
      let id = this.element.attributes.id;
      $("#" + id).attr("text-anchor", "start");
      $("#" + id).attr("alignment-baseline", "central");
      EventBus.$emit("SVG_EDITED");
    },

    rightAlignText() {

      let id = this.element.attributes.id;

      //measure width of element and put x to the end
      let boundingBox = $("#" + id)[0].getBBox();
      //get x and add half of width
      let x = parseFloat($("#" + id).attr("x"));
      x = x + boundingBox.width;
      $("#" + id).attr("x", x);

      $("#" + id).attr("text-anchor", "end");
      $("#" + id).attr("alignment-baseline", "central");

      EventBus.$emit("SVG_EDITED");
    },

    centerText() {
      let id = this.element.attributes.id;
      let $element = $("#" + id);
      
      $element.attr("text-anchor", "middle");
      $element.attr("dominant-baseline", "central"); // Verwenden Sie 'dominant-baseline' statt 'alignment-baseline'
      
      let svgBBox = $("#editedSVG svg")[0].getBBox();
      let newX = svgBBox.width / 2;
      let newY = parseFloat($element.attr("y")); // Behalten Sie die ursprüngliche Y-Position bei
      
      $element.attr("x", newX);
      $element.attr("y", newY);

      this.element.attributes.x = newX;
      this.element.attributes.y = newY;

      EventBus.$emit("SVG_EDITED");
    },

    operationTextWidth(element, maxWidth, minWidth) {
      maxWidth = parseFloat(maxWidth);
      let id = element.attributes.id;
      let boundingBox = $("#" + id)[0].getBBox();
      console.log(boundingBox.width, maxWidth);
      if (boundingBox.width > maxWidth) {
        //get font size
        let fontSize = $("#" + id)[0].style["font-size"];
        fontSize = fontSize.replace("px", "");
        fontSize = parseInt(fontSize);
        while (boundingBox.width > maxWidth) {
          fontSize--;
          $("#" + id)[0].style["font-size"] = fontSize + "px";
          boundingBox = $("#" + id)[0].getBBox();
        }
        element.attributes.fontSize = fontSize;
      } else {
        //get font size
        let fontSize = $("#" + id)[0].style["font-size"];
        fontSize = fontSize.replace("px", "");
        fontSize = parseInt(fontSize);
        while (boundingBox.width < minWidth) {
          fontSize++;
          $("#" + id)[0].style["font-size"] = fontSize + "px";
          boundingBox = $("#" + id)[0].getBBox();
        }
        element.attributes.fontSize = fontSize;
      }
    },

    operationTextHeight(element, maxHeight, minHeight) {
      maxHeight = parseFloat(maxHeight);
      let id = element.attributes.id;
      let boundingBox = $("#" + id)[0].getBBox();
      console.log(boundingBox.height, maxHeight);
      if (boundingBox.height > maxHeight) {
        //get font size
        let fontSize = $("#" + id)[0].style["font-size"];
        fontSize = fontSize.replace("px", "");
        fontSize = parseInt(fontSize);
        while (boundingBox.height > maxHeight) {
          fontSize--;
          $("#" + id)[0].style["font-size"] = fontSize + "px";
          boundingBox = $("#" + id)[0].getBBox();
        }
        element.attributes.fontSize = fontSize;
      } else {
        //get font size
        let fontSize = $("#" + id)[0].style["font-size"];
        fontSize = fontSize.replace("px", "");
        fontSize = parseInt(fontSize);
        while (boundingBox.height < minHeight) {
          fontSize++;
          $("#" + id)[0].style["font-size"] = fontSize + "px";
          boundingBox = $("#" + id)[0].getBBox();
        }
        element.attributes.fontSize = fontSize;
      }
    },

    operationTextSize(element, maxWidth, maxHeight) {
      // Parse the dimensions to ensure they're numeric.
      maxWidth = parseFloat(maxWidth);
      maxHeight = parseFloat(maxHeight);

      // Cache the jQuery object and get the initial bounding box.
      var $element = $("#" + element.attributes.id);
      var boundingBox = $element[0].getBBox();
      var initialFontSize = parseInt($element.css("font-size"));

      // Helper function to adjust font size by bounding box ratio.
      function adjustFontSize(maxDimension, dimension) {
        let currentSize = boundingBox[dimension];
        let desiredSize = maxDimension;
        let ratio = desiredSize / currentSize;
        return Math.floor(initialFontSize * ratio);
      }

      // Decide whether to adjust by width or height by checking which one exceeds its max the most.
      let widthRatio = maxWidth / boundingBox.width;
      let heightRatio = maxHeight / boundingBox.height;
      let fontSize;

      if (widthRatio < heightRatio) {
        // Width is the limiting factor.
        fontSize = adjustFontSize(maxWidth, 'width');
      } else {
        // Height is the limiting factor.
        fontSize = adjustFontSize(maxHeight, 'height');
      }

      // Apply the new font size.
      $element.css("font-size", fontSize + "px");

      // Recheck bounding box and adjust if necessary.
      boundingBox = $element[0].getBBox();
      if (boundingBox.width > maxWidth || boundingBox.height > maxHeight) {
        // If still over max dimensions, decrease font size incrementally.
        while (boundingBox.width > maxWidth || boundingBox.height > maxHeight) {
          fontSize--;
          $element.css("font-size", fontSize + "px");
          boundingBox = $element[0].getBBox();
        }
      }

      element.attributes.fontSize = fontSize;
    },

    fixWhiteImagePixels(s3Url) {
      //send post fixImage request
      EventBus.$emit("fixImage", s3Url);

    },


  },
};
</script>