<template>
  <div class="enhanced-multiselect">
    <v-combobox
      outlined
      dense
      multiple
      v-model="selectedItems"
      :items="targetIds"
      label="Ziel"
      @change="updateOption"
      menu-props="auto"
      ref="combobox"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip
          :key="index"
          :input-value="selectedItems.includes(item)"
          close
          @click:close="remove(item)"
        >
          {{ item }}
        </v-chip>
      </template>
      
      <template v-slot:item="{ item }">
        <v-list-item
          @click.stop.prevent="handleItemClick($event, item)"
        >
          <template v-slot:default="{  }">
            <v-list-item-action>
              <v-checkbox
                :input-value="selectedItems.includes(item)"
                color="primary"
              ></v-checkbox>
            </v-list-item-action>
            
            <v-list-item-content>
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
export default {
  name: 'EnhancedMultiSelect',
  
  props: {
    value: {
      type: Array,
      default: () => []
    },
    targetIds: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      selectedItems: [],
      lastClickedItem: null,
      lastActionWasSelect: true,  // Neue Variable für den letzten Aktionstyp
      isShiftPressed: false
    }
  },

  created() {
    this.selectedItems = Array.isArray(this.value) ? [...this.value] : []
  },

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)
  },

  methods: {
    handleKeyDown(event) {
      if (event.key === 'Shift' || event.keyCode === 16) {
        this.isShiftPressed = true
        console.log('Shift pressed')
      }
    },

    handleKeyUp(event) {
      if (event.key === 'Shift' || event.keyCode === 16) {
        this.isShiftPressed = false
        console.log('Shift released')
      }
    },

    getItemIndex(item) {
      return this.targetIds.indexOf(item)
    },

    handleItemClick(event, item) {
      const currentIndex = this.getItemIndex(item)
      const lastIndex = this.lastClickedItem ? this.getItemIndex(this.lastClickedItem) : null
      const isItemSelected = this.selectedItems.includes(item)

      console.log('Item clicked:', currentIndex, 'Item:', item)
      console.log('Last clicked index:', lastIndex)
      console.log('Shift pressed:', this.isShiftPressed)
      console.log('Last action was select:', this.lastActionWasSelect)

      if (this.isShiftPressed && lastIndex !== null) {
        // Bereichsauswahl
        const start = Math.min(lastIndex, currentIndex)
        const end = Math.max(lastIndex, currentIndex)
        
        console.log('Selection range:', start, 'to', end)
        
        // Hole die Items im Bereich
        const itemsInRange = this.targetIds.slice(start, end + 1)
        console.log('Items in range:', itemsInRange)
        
        if (this.lastActionWasSelect) {
          // Items zur Auswahl hinzufügen
          this.selectedItems = [...new Set([...this.selectedItems, ...itemsInRange])]
        } else {
          // Items aus der Auswahl entfernen
          this.selectedItems = this.selectedItems.filter(item => !itemsInRange.includes(item))
        }
      } else {
        // Toggle einzelnes Item
        if (isItemSelected) {
          // Item entfernen
          this.selectedItems = this.selectedItems.filter(i => i !== item)
          this.lastActionWasSelect = false
        } else {
          // Item hinzufügen
          this.selectedItems = [...this.selectedItems, item]
          this.lastActionWasSelect = true
        }
        // Aktualisiere lastClickedItem nur bei Einzelauswahl
        this.lastClickedItem = item
      }

      // Emittiere die Änderungen
      this.$emit('input', this.selectedItems)
      this.$emit('change', this.selectedItems)
    },

    updateOption(items) {
      if (Array.isArray(items) && JSON.stringify(items) !== JSON.stringify(this.selectedItems)) {
        this.selectedItems = [...items]
        this.$emit('input', items)
      }
    },

    remove(item) {
      const index = this.selectedItems.indexOf(item)
      if (index >= 0) {
        this.selectedItems.splice(index, 1)
        if (this.lastClickedItem === item) {
          this.lastClickedItem = null
        }
        this.$emit('input', this.selectedItems)
      }
    }
  },

  watch: {
    value: {
      handler(newVal) {
        if (Array.isArray(newVal)) {
          this.selectedItems = [...newVal]
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>