import Vue from 'vue';
import PrimeVue from 'primevue/config';

// Import PrimeVue styles
import 'primevue/resources/themes/md-dark-deeppurple/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

//import ChartJS
import Chart from 'chart.js';

const primevueConfig = {
    ripple: true
};

Vue.use(PrimeVue, primevueConfig);
Vue.prototype.$chart = Chart;

export default PrimeVue;