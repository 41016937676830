//Picture service
import Api from '@/services/Api'

export default {
    getProducts() {
        return Api().get('analytics/products')
    },

    getOrders(skus) {
        return Api().post(`analytics/designs`, { skus: skus })
    },
}
