
<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="align-end">
        <v-btn icon @click="addOption()"><v-icon>mdi-plus</v-icon></v-btn>
      </v-col>
      <v-col cols="12" v-if="poster && poster.options">

        <div v-for="o of poster.options" :key="o.field">

          <v-select outlined dense :items="options.type" v-model="o.type" label="Typ" @change="updateOption(o)"></v-select>
          <v-select outlined dense :items="options.field" v-model="o.field" label="Feld" @change="updateOption(o)"></v-select>

          <!-- Text Field-->
          <v-text-field outlined dense v-model="o.maxChar" v-if="o.type == 'text'" label="Maximale Zeichen" @change="updateOption(o)"></v-text-field>
          <v-text-field outlined dense v-model="o.linkTemplate" v-if="o.type == 'imageLink'" label="Link Prefix" @change="updateOption(o)"></v-text-field>
          <v-text-field outlined dense v-model="o.inputType" v-if="o.type == 'imageLink' || o.type == 'info'" label="Input Type" @change="updateOption(o)"></v-text-field>
          
          <v-select outlined dense :items="fontSets" v-model="o.fontSet" v-if="o.type == 'fontSet'" label="Schriftart Set"  @change="updateOption(o)">
            <template #selection="{ item }">
              {{ item.name }}
            </template>
            <template #item="{ item }">
              {{ item.name }}
            </template>
          </v-select>

          <!-- multiple choice of targetIds -->
          
          
          <v-text-field outlined dense v-model="o.label" label="Label" @change="updateOption(o)"></v-text-field>
          
          <!-- HERE-->
          
          <enhanced-multi-select
          v-model="o.targetId"
          :target-ids="targetIds"
          v-if="o.type == 'optionSelect' || o.type == 'color' || o.type == 'fontSet'"
          @change="updateOption(o)"
        />
          <!-- <v-combobox outlined dense multiple  v-model="o.targetId" :items="targetIds" label="Ziel" @change="updateOption(o)"></v-combobox>-->


          <v-select outlined dense  v-else-if="o.type != 'info'" :items="targetIds" v-model="o.targetId" label="Ziel" @change="updateOption(o)"></v-select>
          <v-btn dense @click="deleteOption(o)">Feld Löschen</v-btn>
          <hr class="mb-5" />
        </div>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
/* eslint-disable */

import EnhancedMultiSelect from './EnhancedMultiSelect.vue'

export default {
  name: "DataProperties",
  props: ["poster", "targetIds", "fontSets"],
  components: {
    EnhancedMultiSelect
  },
  data() {
    return {
      element: null,
      selectedField: null,
      options: {
        type: ["text", "image", "color", "mapbox", "optionSelect", "fontSet", "imageLink", "info"],
        field: ["line1", "line2", "line3", "line4", "line5", "line6", "line7", "line8", "line9", "line10", "image1", "image2", "image3", "image4", "image5", "image6", "image7", "image8", "image9", "image10", "font1", "font2", "font3", "font4", "font5", "font6", "font7", "font8", "font9", "font10", "motiv1", "motiv2", "image1", "image2", "image3", "image4", "image5", "image6", "image7", "image8", "image9", "image10", "color1", "color2", "color3", "color4", "color5", "color6", "color7", "color8", "color9", "color10"],
      }
    };
  },
  computed: {

  },

  watch: {
    poster: {
      handler: function () {
        console.log(this.poster)
        console.log("Poster changed")
      },
      deep: true
    }
  },

  methods: {

    updateElement(element) {
      this.element = element;
      this.selectedField = null;
    },

    addOption() {
      console.log(this.poster)
      this.poster.options.push({
        type: "text",
        field: null,
        maxChar: null,
        label: "",
        targetId: null
      })
    },

    updateOption(option) {
      console.log(option)
    },

    deleteOption(option) {
      this.poster.options.splice(this.poster.options.indexOf(option), 1);
    },
  },
};
</script>