<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" sm="9">
        <v-autocomplete
          v-model="values"
          :items="items"
          item-text="sku"
          item-value="sku"
          label="Wähle SKUs"
          multiple
          chips
          deletable-chips
          :search-input.sync="search"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 3" small>
              <span>{{ item.sku }}</span>
            </v-chip>
            <span v-if="index === 3" class="grey--text text-caption">
              (+{{ values.length - 3 }} weitere)
            </span>
          </template>
          <template v-slot:prepend-item>
            <v-list-item
              v-if="filteredItems.length > 0"
              @click="selectAllFiltered"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Alle gefilterten Ergebnisse auswählen ({{ filteredItems.length }})
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" class="d-flex justify-end">
        <v-btn color="error" @click="clearSelection" class="mr-2">
          Vergleich löschen
        </v-btn>
        <v-btn color="primary" @click="loadOrders">
          Vergleich erstellen
        </v-btn>
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="loading" type="card" class="mt-4"></v-skeleton-loader>

    <template v-if="!loading && filteredOrders.length > 0">
      <v-card v-if="filteredOrders.length > 1" class="mt-4">
        <v-card-title class="headline">
          SKU Vergleich
        </v-card-title>
        <v-card-text>
          <v-data-table 
            :headers="getComparisonHeaders()" 
            :items="comparisonData" 
            :sort-by="['totalCount']" 
            :sort-desc="[true]" 
            :items-per-page="10"
            class="mt-4"
          >
            <template v-slot:top>
              <v-text-field v-model="comparisonSearch" label="Search Target ID" class="mx-4"></v-text-field>
            </template>
          </v-data-table>

          <Chart type="bar" :data="getComparisonChartData()" :options="comparisonChartOptions" class="h-[30rem] mt-4"/>
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import AuthService from '../services/AuthService';
import service from '../services/AnalyticsService';
import Chart from 'primevue/chart';

export default {
  components: {
    Chart
  },
  data() {
    return {
      loading: false,
      items: [],
      values: [],
      orders: [],
      filteredOrders: [],
      productData: [],
      comparisonSearch: '',
      search: null,
      chartOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      },
      comparisonChartOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            stacked: true,
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      }
    }
  },
  computed: {
    comparisonData() {
      const allTargetIds = new Set();
      this.filteredOrders.forEach(item => {
        item.targetIdAnalytics.forEach(analytic => {
          allTargetIds.add(analytic.targetId);
        });
      });

      return Array.from(allTargetIds).map(targetId => {
        const data = { targetId };
        let totalCount = 0;
        this.filteredOrders.forEach(item => {
          const analytic = item.targetIdAnalytics.find(a => a.targetId === targetId);
          data[item.sku] = analytic ? analytic.count : 0;
          totalCount += data[item.sku];
        });
        data.totalCount = totalCount;
        return data;
      }).filter(item => 
        item.targetId.toLowerCase().includes(this.comparisonSearch.toLowerCase())
      );
    },
    filteredItems() {
      if (!this.search) return this.items;
      return this.items.filter(item => 
        item.sku.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  async created() {
    try {
      this.user = (await AuthService.getUser()).data
      this.loadItems();
    } catch (err) {
      console.error(err)
      if (!this.user) this.$router.push('/auth/login');
    }
  },
  methods: {
    async loadItems() {
      this.loading = true;
      let products = (await service.getProducts()).data;

      this.productData = products;

      products.forEach(product => {
        if (typeof product.sku === 'string') {
          this.items.push({ sku: product.sku, targetId: product.targetId })
        } else if (Array.isArray(product.sku)) {
          product.sku.forEach(sku => {
            this.items.push({ sku: sku, targetId: product.targetId })
          });
        }
      })

      this.items.sort((a, b) => a.sku.localeCompare(b.sku));
      this.loading = false;
    },
    async loadOrders() {
      this.loading = true;

      let skus = this.values;
      let orders = (await service.getOrders(skus)).data;

      this.filteredOrders = this.filterOrders(skus, orders);
      this.orders = orders;

      this.filteredOrders.forEach(item => this.calculateTargetIdAnalytics(item));

      this.loading = false;
    },
    filterOrders(skus, orders) {
      let filteredOrders = skus.map(sku => {
        const product = this.productData.find(p =>
          p.sku === sku || (Array.isArray(p.sku) && p.sku.includes(sku))
        );
        return {
          sku: sku,
          orders: orders.filter(order => order.sku === sku),
          targetIds: product ? product.targetId : [],
          field: product ? product.field : '',
          label: product ? product.label : '',
          searchTargetId: '',
          targetIdAnalytics: []
        };
      });
      console.log(filteredOrders);
      return filteredOrders;
    },
    clearSelection() {
      this.values = [];
      this.filteredOrders = [];
    },
    calculateTargetIdAnalytics(item) {
      let targetField = item.field;
      item.targetIdAnalytics = item.targetIds.map((targetId, index) => ({
        targetId: targetId,
        count: item.orders.filter(o => o[targetField] && (o[targetField] === (index + 1) || o[targetField] === targetId)).length
      }));
    },
    removeSelection(sku) {
      this.values = this.values.filter(value => value !== sku);
      this.filteredOrders = this.filteredOrders.filter(item => item.sku !== sku);
    },
    getHeaders(label) {
      return [
        { text: label || 'Target ID', value: 'targetId' },
        { text: 'Count', value: 'count' },
      ];
    },
    getChartData(item) {
      const labels = item.targetIdAnalytics.map(analytic => analytic.targetId);
      const data = item.targetIdAnalytics.map(analytic => analytic.count);

      return {
        labels: labels,
        datasets: [
          {
            label: `Orders for ${item.sku}`,
            data: data,
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            borderWidth: 1
          }
        ]
      };
    },
    getComparisonHeaders() {
      const headers = [
        { text: 'Target ID', value: 'targetId' },
        ...this.filteredOrders.map(item => ({ text: item.sku, value: item.sku })),
        { text: 'Total', value: 'totalCount' }
      ];
      return headers;
    },
    getComparisonChartData() {
      const labels = this.comparisonData.map(item => item.targetId);
      const datasets = this.filteredOrders.map((order, index) => ({
        label: order.sku,
        data: this.comparisonData.map(item => item[order.sku]),
        backgroundColor: this.getColor(index),
        borderColor: this.getColor(index),
        borderWidth: 1
      }));

      return {
        labels: labels,
        datasets: datasets
      };
    },
    getColor(index) {
      const colors = ['#42A5F5', '#66BB6A', '#FFA726', '#26C6DA', '#7E57C2', '#EC407A', '#AB47BC', '#8D6E63', '#D4E157', '#FF7043'];
      return colors[index % colors.length];
    },
    selectAllFiltered() {
      const filteredSkus = this.filteredItems.map(item => item.sku);
      this.values = [...new Set([...this.values, ...filteredSkus])];
    }
  },
}
</script>